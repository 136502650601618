import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"

const pageStyles = {
  maxWidth: "950px",
  overflow: "hidden",
  wordWrap: "break-word",
  backgroundColor: "#ffffff",
}
const linkStyles = {
  color: "#0000ff",
  padding: "2% 5% 0%",
  fontSize: "1.5em",
  fontWeight: "bold",
}
const headingStyles = {
  color: "#000000",
  fontFamily: "Bona Nova, serif",
  fontSize: "2.75em",
  lineHeight: "1.5",
  fontWeight: "700",
  padding: "2% 5% 0%",
}
const paragraphStyles = {
  color: "#000000",
  fontFamily: "Verdana, sans-serif",
  fontSize: "1.125em",
  lineHeight: "1.5",
  fontWeight: "400",
  padding: "2% 5% 0%",
}
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
    <h1 style={headingStyles}>Ошибка 404</h1>
    <p style={paragraphStyles}>К сожалению, запрашиваемая страница временно недоступна или была удалена !</p>
    <ul style={linkStyles}>
    <Link to="/">Главная страница</Link></ul>
    </main>
 )
}
export const Head = () => (
  <Seo title="Ошибка 404 - Страница не найдена!"
    description="DosugRU.Net: Ошибка 404 - Страница не найдена!">
    <meta name="robots" content="noindex,nofollow"/>
  </Seo>
)
export default NotFoundPage